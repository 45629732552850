/**
 * Copyright 2022 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import LoggerFactory from '../../logger/LoggerFactory';
import IDisposable from '../../lang/IDisposable';
import ReadOnlySubject from '../../rx/ReadOnlySubject';
import Dimension from '../../video/Dimension';
import EndPoint, {IStream} from '../discovery/EndPoint';
import SDK from '../SDK';
import IPeerConnection from '../../rtc/IPeerConnection';
import ChannelState from './ChannelState';
import assertUnreachable from '../../lang/assertUnreachable';
import Disposable from '../../lang/Disposable';
import EdgeAuth from '../edgeAuth/EdgeAuth';
import {ILogger} from '../../logger/LoggerInterface';
import VideoTelemetry from '../../video/VideoTelemetry';
import SessionTelemetry from '../../video/SessionTelemetry';
import {IRtcMonitorStatistic} from '../../rtc/RtcConnectionMonitor';
import {BitrateMode, BitrateState} from '../api/SetTemporaryMaximalBitrate';

import {EdgeToken} from '../edgeAuth/EdgeToken';
import {BitsPerSecond, Millisecond} from '../../units/Units';
import ChannelContext from './ChannelContext';
import StreamFactory from '../streaming/StreamFactory';
import DiscoveryUri from '../discovery/DiscoveryUri';
import MetricsFactory from '../../metrics/MetricsFactory';
import MetricsService from '../../metrics/MetricsService';

const defaultTargetLag = 0;
const defaultStreamTerminationReason = 'client:termination';
const backoffIntervalInMilliseconds = 2000;
const failureCountCleanUpIntervalInMilliseconds = 3000;
const maxBackoffIntervalInMilliseconds = 300000;
const standbyPollingIntervalInMilliseconds = 15000;

export default class Channel implements IDisposable {
  private readonly _logger: ILogger = LoggerFactory.getLogger('Channel');
  private readonly _context: ChannelContext;
  private readonly _channelStartTime: number;
  private readonly _readOnlyVideoElement: ReadOnlySubject<HTMLVideoElement>;
  private readonly _readOnlyToken: ReadOnlySubject<EdgeToken>;
  private readonly _readOnlyPeerConnection: ReadOnlySubject<IPeerConnection>;
  private readonly _readOnlyState: ReadOnlySubject<ChannelState>;
  private readonly _readOnlyAutoMuted: ReadOnlySubject<boolean>;
  private readonly _readOnlyAutoPaused: ReadOnlySubject<boolean>;
  private readonly _readOnlyTokenExpiring: ReadOnlySubject<boolean>;
  private readonly _readOnlyAuthorized: ReadOnlySubject<boolean>;
  private readonly _readOnlyOnline: ReadOnlySubject<boolean>;
  private readonly _readOnlyLoading: ReadOnlySubject<boolean>;
  private readonly _readOnlyPlaying: ReadOnlySubject<boolean>;
  private readonly _readOnlyStandby: ReadOnlySubject<boolean>;
  private readonly _readOnlyStopped: ReadOnlySubject<boolean>;
  private readonly _readOnlyTargetLag: ReadOnlySubject<Millisecond>;
  private readonly _readOnlyLag: ReadOnlySubject<Millisecond>;
  private readonly _readOnlyBitrateLimit: ReadOnlySubject<BitsPerSecond>;
  private readonly _readOnlyResolution: ReadOnlySubject<Dimension>;
  private readonly _readOnlyFailureCount: ReadOnlySubject<number>;
  private readonly _readOnlyEndPoint: ReadOnlySubject<EndPoint>;
  private readonly _readOnlyStream: ReadOnlySubject<IStream>;
  private readonly _readOnlyRtcStatistics: ReadOnlySubject<IRtcMonitorStatistic>;
  private readonly _readOnlyMediaStream: ReadOnlySubject<MediaStream>;

  private _metricsService: MetricsService;
  private readonly _videoMetaDataChangedHandler: () => void;

  constructor(videoElement: HTMLVideoElement, token: string, targetLag: number = defaultTargetLag) {
    this._context = new ChannelContext(token, targetLag);
    this._channelStartTime = Date.now();
    this._readOnlyVideoElement = new ReadOnlySubject<HTMLVideoElement>(this._context.videoElement);
    this._readOnlyToken = new ReadOnlySubject<string>(this._context.token);
    this._readOnlyPeerConnection = new ReadOnlySubject<IPeerConnection>(this._context.peerConnection);
    this._readOnlyState = new ReadOnlySubject<ChannelState>(this._context.state);
    this._readOnlyAutoMuted = new ReadOnlySubject<boolean>(this._context.autoMuted);
    this._readOnlyAutoPaused = new ReadOnlySubject<boolean>(this._context.autoPaused);
    this._readOnlyTokenExpiring = new ReadOnlySubject<boolean>(this._context.tokenExpiring);
    this._readOnlyAuthorized = new ReadOnlySubject<boolean>(this._context.authorized);
    this._readOnlyOnline = new ReadOnlySubject<boolean>(this._context.online);
    this._readOnlyLoading = new ReadOnlySubject<boolean>(this._context.loading);
    this._readOnlyPlaying = new ReadOnlySubject<boolean>(this._context.playing);
    this._readOnlyStandby = new ReadOnlySubject<boolean>(this._context.standby);
    this._readOnlyStopped = new ReadOnlySubject<boolean>(this._context.stopped);
    this._readOnlyTargetLag = new ReadOnlySubject<number>(this._context.targetLag);
    this._readOnlyLag = new ReadOnlySubject<number>(this._context.lag);
    this._readOnlyBitrateLimit = new ReadOnlySubject<number>(this._context.bitrateLimit);
    this._readOnlyResolution = new ReadOnlySubject<Dimension>(this._context.resolution);
    this._readOnlyFailureCount = new ReadOnlySubject<number>(this._context.failureCount);
    this._readOnlyEndPoint = new ReadOnlySubject<EndPoint>(this._context.endPoint);
    this._readOnlyStream = new ReadOnlySubject<IStream>(this._context.stream);
    this._readOnlyRtcStatistics = new ReadOnlySubject<IRtcMonitorStatistic>(this._context.rtcStatistics);
    this._readOnlyMediaStream = new ReadOnlySubject<MediaStream>(this._context.mediaStream);

    const parsedToken = EdgeAuth.parseToken(this._context.token.value);
    const discoveryUri = (EdgeAuth.getUri(parsedToken) || SDK.discoveryUri.value).toString();

    SDK.tenancy.value = EdgeAuth.getTenancy(parsedToken) || SDK.tenancy.value;
    DiscoveryUri.uri.value = discoveryUri;
    this._metricsService = MetricsFactory.getMetricsService(discoveryUri);
    this._context.sessionTelemetry = new SessionTelemetry(SDK.pageLoadTime, this._metricsService);
    this._context.channelDisposables.add(this._context.sessionTelemetry);
    this._videoMetaDataChangedHandler = this.handleVideoMetaDataChanged.bind(this);
    this.videoElement = videoElement;

    this._context.channelDisposables.add(
      this._context.videoElement.subscribe(videoElement => {
        this._context.rendererDisposables.dispose();

        if (!videoElement) {
          return;
        }

        this._context.rendererDisposables.add(this._context.stream.subscribe(stream => {
          if (this._context.videoTelemetry) {
            this._context.videoTelemetry.dispose();
          }

          if (!stream) {
            return;
          }

          if (!this.videoElement) {
            return;
          }

          if (this.videoElement.dataset) {
            this.videoElement.dataset.sessionId = SDK.clientSessionId;
            this.videoElement.dataset.streamId = this.streamId;
          }

          this._context.videoTelemetry = new VideoTelemetry(this.streamId, SDK.pageLoadTime, this._channelStartTime, this._metricsService);
          this._context.videoTelemetry.setupListenerForTimeToFirstTime(this.videoElement);
          this._context.videoTelemetry.setupListenerForRebuffering(this.videoElement);

          if (this._context.state.value === ChannelState.Stopped) {
            const ignored = this.restartAfterStop();
          }
        }));

        this._context.channelDisposables.add(this._context.rendererDisposables);
      }));
    this._context.channelDisposables.add(
      this._context.state.subscribe(state => {
        if (this._context.clearFailureCountTimeout) {
          clearTimeout(this._context.clearFailureCountTimeout);
        }

        if (!this._context.failureCount.value) {
          return;
        }

        if (state !== ChannelState.Playing) {
          return;
        }

        this._context.clearFailureCountTimeout = window.setTimeout(() => {
          this._context.failureCount.value = 0;
        }, failureCountCleanUpIntervalInMilliseconds);
      }));
    this._context.channelDisposables.add(
      this._context.resolution.subscribe(resolution => {
        if (this._context.videoTelemetry) {
          this._context.videoTelemetry.onVideoResolutionChanges(resolution.toString());
        }
      }));
    this._context.channelDisposables.add(
      this._context.bitrateLimit.subscribe(bitrateLimit => {
        if (bitrateLimit && this._context.endPoint.value && this._context.stream.value) {
          const elapsedInMilliseconds = Date.now() - this._context.channelInitialization.getTime();
          const ignored = this._context.endPoint.value.limitBitrate(
            this._context.stream.value,
            elapsedInMilliseconds,
            bitrateLimit,
            BitrateState.Keep,
            BitrateMode.Normal
          )
            .catch(e => {
              this._logger.error('Error while setting limit bitrate', e);
            });
        }
      })
    );

    this.start();
  }

  get videoElement(): HTMLVideoElement {
    return this._context.videoElement.value;
  }

  set videoElement(videoElement: HTMLVideoElement) {
    if (this._context.videoElement.value) {
      this._context.videoElement.value.removeEventListener('loadeddata', this._videoMetaDataChangedHandler);
      this._context.videoElement.value.removeEventListener('loadedmetadata', this._videoMetaDataChangedHandler);
      this._context.videoElement.value.removeEventListener('resize', this._videoMetaDataChangedHandler);

      if (this._context.videoElement.value.dataset) {
        this._context.videoElement.value.dataset.sessionId = '';
        this._context.videoElement.value.dataset.streamId = '';
      }

      this._context.rendererDisposables.dispose();

      this._context.videoElement.value.pause();
      this._context.videoElement.value.srcObject = null;
    }

    this._context.autoMuted.value = false;
    this._context.autoPaused.value = false;
    this._context.loading.value = false;
    this._context.playing.value = false;
    this._context.state.value = ChannelState.Stopped;

    this._context.videoElement.value = videoElement;

    if (this._context.videoElement.value) {
      this._context.videoElement.value.addEventListener('loadeddata', this._videoMetaDataChangedHandler);
      this._context.videoElement.value.addEventListener('loadedmetadata', this._videoMetaDataChangedHandler);
      this._context.videoElement.value.addEventListener('resize', this._videoMetaDataChangedHandler);
    }
  }

  private handleVideoMetaDataChanged(): void {
    const videoElement = this._context.videoElement.value;

    if (videoElement) {
      if (this.resolution.value.width !== videoElement.videoWidth || this.resolution.value.height !== videoElement.videoHeight) {
        this._context.resolution.value = new Dimension(videoElement.videoWidth, videoElement.videoHeight);
      }
    } else {
      this._context.resolution.value = Dimension.empty;
    }
  }

  get token(): EdgeToken {
    return this._context.token.value;
  }

  set token(token: EdgeToken) {
    this._context.disposables.dispose();

    this._context.token.value = token;
    this._context.tokenExpiring.value = false;

    const parsedToken = EdgeAuth.parseToken(this._context.token.value);
    const discoveryUri = (EdgeAuth.getUri(parsedToken) || SDK.discoveryUri.value).toString();

    SDK.tenancy.value = EdgeAuth.getTenancy(parsedToken) || SDK.tenancy.value;
    DiscoveryUri.uri.value = discoveryUri;

    this._metricsService = MetricsFactory.getMetricsService(discoveryUri);

    this.start();
  }

  get peerConnection(): ReadOnlySubject<IPeerConnection> {
    return this._readOnlyPeerConnection;
  }

  get state(): ReadOnlySubject<ChannelState> {
    return this._readOnlyState;
  }

  get autoMuted(): ReadOnlySubject<boolean> {
    return this._readOnlyAutoMuted;
  }

  get autoPaused(): ReadOnlySubject<boolean> {
    return this._readOnlyAutoPaused;
  }

  get tokenExpiring(): ReadOnlySubject<boolean> {
    return this._readOnlyTokenExpiring;
  }

  get authorized(): ReadOnlySubject<boolean> {
    return this._readOnlyAuthorized;
  }

  get online(): ReadOnlySubject<boolean> {
    return this._readOnlyOnline;
  }

  get loading(): ReadOnlySubject<boolean> {
    return this._readOnlyLoading;
  }

  get playing(): ReadOnlySubject<boolean> {
    return this._readOnlyPlaying;
  }

  get standby(): ReadOnlySubject<boolean> {
    return this._readOnlyStandby;
  }

  get stopped(): ReadOnlySubject<boolean> {
    return this._readOnlyStopped;
  }

  get targetLag(): ReadOnlySubject<Millisecond> {
    return this._readOnlyTargetLag;
  }

  get lag(): ReadOnlySubject<Millisecond> {
    return this._readOnlyLag;
  }

  get bitrateLimit(): number {
    return this._readOnlyBitrateLimit.value;
  }

  get resolution(): ReadOnlySubject<Dimension> {
    return this._readOnlyResolution;
  }

  get failureCount(): ReadOnlySubject<number> {
    return this._readOnlyFailureCount;
  }

  get endPoint(): ReadOnlySubject<EndPoint> {
    return this._readOnlyEndPoint;
  }

  get stream(): ReadOnlySubject<IStream> {
    return this._readOnlyStream;
  }

  get streamId(): string {
    return this._context.streamId;
  }

  get rtcStats(): ReadOnlySubject<IRtcMonitorStatistic> {
    return this._readOnlyRtcStatistics;
  }

  get mediaStream(): ReadOnlySubject<MediaStream> {
    return this._readOnlyMediaStream;
  }

  setBitrateLimit(bitrateLimit: BitsPerSecond): void {
    this._context.bitrateLimit.value = bitrateLimit;
  }

  clearBitrateLimit(): void {
    if (this._context.bitrateLimit.value && this._context.endPoint.value && this._context.stream.value) {
      const elapsedInMilliseconds = Date.now() - this._context.channelInitialization.getTime();
      const bitrateInBitsPerSecond = 0;
      const ignored = this._context.endPoint.value.limitBitrate(
        this._context.stream.value,
        elapsedInMilliseconds,
        bitrateInBitsPerSecond,
        BitrateState.Keep,
        BitrateMode.Reset
      )
        .then(({status}) => {
          if (status === 'ok') {
            this._context.bitrateLimit.value = 0;
          }
        })
        .catch(e => {
          this._logger.error('Error while setting limit bitrate', e);
        });
    }
  }

  updateTargetLag(lag: Millisecond): void {
    this._context.targetLag.value = lag;
  }

  async stop(reason: string): Promise<void> {
    return new Promise(resolve => {
      if (!this._context.isStarting.value) {
        this.processStop(reason);

        resolve();

        return;
      }

      this._context.rendererDisposables.add(this._context.isStarting.subscribe(isStarting => {
        if (!isStarting) {
          this.processStop(reason);
          resolve();
        }
      }));
    });
  }

  private processStop(reason: string): void {
    if (this._context.videoElement.value) {
      this._context.videoElement.value.pause();
      this._context.videoElement.value.srcObject = null;
    }

    this._context.rendererDisposables.dispose();

    this.cleanUpResources(reason);

    this._context.state.value = ChannelState.Stopped;
  }

  async resume(): Promise<void> {
    if (this._context.mediaStream.value) {
      this._context.autoPaused.value = false;

      return this.playMediaStreamInVideoElement(this._context.mediaStream.value);
    }
  }

  mute(): void {
    const videoElement = this._context.videoElement.value;

    if (videoElement) {
      videoElement.muted = true;
    }
  }

  unmute(): void {
    const videoElement = this._context.videoElement.value;

    if (videoElement) {
      videoElement.muted = false;
      this._context.autoMuted.value = false;
    }
  }

  async dispose(): Promise<void> {
    return this.stop('client:channel-dispose').then(() => {
      this._context.channelDisposables.dispose();
      this._context.isDisposed = true;
    });
  }

  getUri(token): URL {
    const parsedToken = EdgeAuth.parseToken(token);
    const url = EdgeAuth.getUri(parsedToken);

    if (url) {
      return url;
    }

    this._logger.info('Fall back to the default discover URI [%s]', SDK.discoveryUri.value);

    return new URL(SDK.discoveryUri.value);
  }

  async start(): Promise<void> {
    if (this._context.isDisposed) {
      throw new Error('Channel was already disposed');
    }

    if (this._context.isStarting.value) {
      this._logger.info('Channel is already starting, skipping start');

      return;
    }

    this._context.isStarting.value = true;

    return this.processStart();
  }

  private async processStart(): Promise<void> {
    const token = this._context.token.value;
    const listenOnStreamSetup = this._context.sessionTelemetry.listenOnStreamSetup();

    if (!EdgeAuth.isValidToken(token)) {
      this._logger.error('Failed to parse token [%s]', token);
      this._context.state.value = ChannelState.Unauthorized;
      this._context.authorized.value = false;
      this._context.isStarting.value = false;

      return;
    }

    this.cleanUpResources('client:start');
    this._context.state.value = ChannelState.Starting;
    this._context.loading.value = true;

    const uri = this.getUri(token);
    const handleStreamFailureCallback: () => Promise<void> = () => new Promise((resolve): void => {
      // Need to set isStarting to false and call handleStreamFailure if stream monitors found an issue
      this._context.isStarting.value = false;

      return resolve(this.handleStreamFailure());
    });
    const streamPlayer = StreamFactory.create(token, this._context, handleStreamFailureCallback);

    if (!streamPlayer) {
      this._context.isStarting.value = false;

      return;
    }

    return streamPlayer.start(
      uri,
      token,
      listenOnStreamSetup,
      this.playMediaStreamInVideoElement.bind(this))
      .then(() => {
        this._context.loading.value = false;
      })
      .catch(e => {
        listenOnStreamSetup.fail();

        this._context.failureCount.value++;

        this._context.online.value = false;

        this.cleanUpResources('client:cleanup-after-failed-setup');

        this._context.state.value = ChannelState.Error;

        this._logger.error('Failed to start channel', e);
      })
      .finally(() => {
        this._context.isStarting.value = false;

        if (this._context.state.value === ChannelState.Playing || !SDK.automaticRetryOnFailure) {
          return;
        }

        const timeoutId = setTimeout(() => {
          const ignored = this.handleStreamFailure()
            .catch(e => {
              this._logger.error('Failed handling stream failure', e);
            });
        }, this.getRetryInterval());

        this._context.disposables.add(new Disposable(() => {
          clearTimeout(timeoutId);
        }));
      });
  }

  private async restartAfterStop(): Promise<void> {
    if (this._context.isDisposed) {
      throw new Error('Channel was already disposed');
    }

    if (this._context.mediaStream.value) {
      return this.playMediaStreamInVideoElement(this._context.mediaStream.value);
    }

    if (this._context.token.value) {
      const ignored = this.start();
    }
  }

  public async play(): Promise<void> {
    const mediaStream = this._context.mediaStream.value;

    if (!mediaStream) {
      return this.start();
    }

    return this.playMediaStreamInVideoElement(mediaStream);
  }

  private getRetryInterval(): number {
    switch (this._context.state.value) {
      case ChannelState.StandBy:
      case ChannelState.Offline:
        return standbyPollingIntervalInMilliseconds;
      case ChannelState.Error:
      case ChannelState.Recovering:
      case ChannelState.Unauthorized:
      case ChannelState.GeoRestricted:
      case ChannelState.GeoBlocked:
      case ChannelState.Stopped:
      case ChannelState.Starting:
      case ChannelState.Playing:
      case ChannelState.Paused:
      case ChannelState.Reconnecting:
      case ChannelState.UnsupportedFeature:
        // First and second attempt fast, after that exponential with backoff interval
        return Math.min(maxBackoffIntervalInMilliseconds, Math.pow(backoffIntervalInMilliseconds, Math.max(0, this._context.failureCount.value - 1)));
      default:
        assertUnreachable(this._context.state.value);
    }
  }

  private async handleStreamFailure(): Promise<void> {
    switch (this._context.state.value) {
      case ChannelState.Error:
      case ChannelState.Reconnecting:
      case ChannelState.StandBy:
      case ChannelState.Offline:
      case ChannelState.Recovering:
        this._logger.info('Retry start with initial state [%s] [%s]', this._context.state.value, ChannelState[this._context.state.value]);

        break;
      case ChannelState.Unauthorized:
        this._logger.info('Channel is unauthorized, skipping retry of start. Please provide a new token and invoke start()');

        return;
      case ChannelState.GeoRestricted:
        this._logger.info('Channel is geo restricted, skipping retry of start. Please provide a new token and invoke start()');

        return;
      case ChannelState.GeoBlocked:
        this._logger.info('Channel is geo blocked, skipping retry of start. Please provide a new token and invoke start()');

        return;
      case ChannelState.Stopped:
        this._logger.info('Channel is stopped, skipping retry of start.');

        return;
      case ChannelState.Playing:
        this._logger.info('Channel is playing, skipping retry of start');

        return;
      case ChannelState.Paused:
        this._logger.info('Channel is paused, skipping retry of start. Please invoke play()');

        return;
      case ChannelState.Starting:
        this._logger.info('Channel is already starting, skipping retry of start');

        return;
      case ChannelState.UnsupportedFeature:
        this._logger.info('Channel is stopped due to unsupported feature, skipping retry of start.');

        return;
      default:
        assertUnreachable(this._context.state.value);
    }

    return this.start();
  }

  private cleanUpResources(reason: string = defaultStreamTerminationReason): void {
    this._context.disposables.dispose();

    const peerConnection = this._context.peerConnection.value;

    if (peerConnection) {
      this._context.peerConnection.value = null;
      peerConnection.close();
    }

    if (this._context.mediaStream.value) {
      this._context.mediaStream.value.getTracks().forEach(track => track.stop());
      this._context.mediaStream.value = null;
    }

    this._context.autoPaused.value = false;
    this._context.autoMuted.value = false;
    this._context.playing.value = false;
    this._context.stopped.value = true;
    this._context.standby.value = false;

    if (this._context.stream.value && this._context.endPoint.value) {
      const ignored = this._context.endPoint.value.destroyStream(this._context.stream.value, reason)
        .then(({status}) => {
          if (status !== 'ok') {
            this._logger.warn('[%s] Failed to destroy stream with reason [%s]', this.streamId, status);

            return;
          }

          this._logger.info('[%s] Destroyed stream with reason [%s]', this.streamId, status);
        })
        .catch(e => {
          this._logger.error('[%s] Failed to destroy stream', this.streamId, e);
        });
    }

    if (this.videoElement && this.videoElement.dataset) {
      this.videoElement.dataset.sessionId = '';
      this.videoElement.dataset.streamId = '';
    }

    this._context.stream.value = null;
    this._context.endPoint.value = null;
    this._context.peerConnectionReconnectAttempts = 0;
  }

  private async playMediaStreamInVideoElement(mediaStream: MediaStream): Promise<void> {
    const videoElement = this._context.videoElement.value;

    if (!videoElement) {
      this._context.autoMuted.value = false;
      this._context.autoPaused.value = false;
      this._context.loading.value = false;
      this._context.playing.value = false;
      this._context.state.value = ChannelState.Stopped;

      return;
    }

    videoElement.srcObject = mediaStream;

    const playPromise = videoElement.play();

    if (playPromise === undefined) {
      this._context.autoMuted.value = false;
      this._context.autoPaused.value = false;
      this._context.loading.value = false;
      this._context.playing.value = true;
      this._context.state.value = ChannelState.Playing;

      return;
    }

    return playPromise.then(() => {
      this._context.autoMuted.value = false;
      this._context.autoPaused.value = false;
      this._context.loading.value = false;
      this._context.playing.value = true;
      this._context.state.value = ChannelState.Playing;
    }).catch(e => {
      const hasAudioTrack = !!mediaStream.getTracks().filter(track => {
        return track.kind === 'audio';
      });
      const automaticallyMuteVideoOnPlayFailureOff = !SDK.automaticallyMuteVideoOnPlayFailure;

      if (automaticallyMuteVideoOnPlayFailureOff || videoElement.muted || !hasAudioTrack) {
        this._context.autoMuted.value = false;
        this._context.autoPaused.value = true;
        this._context.loading.value = false;
        this._context.playing.value = false;
        this._context.state.value = ChannelState.Paused;

        if (automaticallyMuteVideoOnPlayFailureOff) {
          this._logger.info('[%s] Paused video after play failed. Manual user action required.', this.streamId, e);
          videoElement.srcObject = null;

          return;
        }

        if (hasAudioTrack) {
          this._logger.info('[%s] Failed to play video. Manual user action required.', this.streamId, e);

          return;
        }

        this._logger.info('[%s] Failed to play muted video. Manual user action required.', this.streamId, e);

        return;
      }

      videoElement.muted = true;

      return videoElement.play()
        .then(() => {
          this._logger.info('[%s] Played video after auto muting. Manual user action required to unmute.', this.streamId);

          this._context.autoMuted.value = true;
          this._context.autoPaused.value = false;
          this._context.loading.value = false;
          this._context.playing.value = true;
          this._context.state.value = ChannelState.Playing;
        }).catch(e => {
          videoElement.muted = false;

          this._logger.info('[%s] Failed to play video. Manual user action required.', this.streamId, e);

          this._context.autoMuted.value = false;
          this._context.autoPaused.value = true;
          this._context.loading.value = false;
          this._context.playing.value = false;
          this._context.state.value = ChannelState.Paused;
        });
    });
  }
}