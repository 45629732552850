/**
 * Copyright 2022 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import {BitrateMode, BitrateModeType} from '../api/SetTemporaryMaximalBitrate';
import assertUnreachable from '../../lang/assertUnreachable';

export default class BitrateModeMapping {
  static convertBitrateModeToBitrateModeType(bitrateMode: BitrateMode): BitrateModeType {
    switch (bitrateMode) {
      case BitrateMode.Normal:
        return 'Normal';
      case BitrateMode.Force:
        return 'Force';
      case BitrateMode.Reset:
        return 'Reset';
      default:
        assertUnreachable(bitrateMode);
    }
  }
}