/**
 * Copyright 2022 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import LoggerFactory from '../../logger/LoggerFactory';
import {ILogger} from '../../logger/LoggerInterface';

import {
  ISubscribeResponse,
  ISubscribeWithOfferRequest,
  ISubscribeWithoutOfferRequest
} from '../api/Subscribe';
import {ISetRemoteDescriptionRequest, ISetRemoteDescriptionResponse} from '../api/SetRemoteDescription';
import {IRtcConfiguration} from '../api/RtcConfiguration';
import {ISessionDescription, SdpType} from '../api/SessionDescription';
import {IDestroyStreamRequest, IDestroyStreamResponse} from '../api/DestroyStream';
import assertUnreachable from '../../lang/assertUnreachable';
import {IAddIceCandidatesRequest, IAddIceCandidatesResponse} from '../api/AddIceCandidates';
import VersionManager from '../version/VersionManager';
import EdgeAuth from '../edgeAuth/EdgeAuth';
import {BitrateMode, BitrateState, ISetTemporaryMaximalBitrate} from '../api/SetTemporaryMaximalBitrate';
import BitrateModeMapping from './BitrateModeMapping';
import BitrateStateMapping from './BitrateStateMapping';
import {BitsPerSecond, Millisecond} from '../../units/Units';
import {
  IPublishResponse,
  IPublishWithOfferRequest,
  IPublishWithoutOfferRequest,
  PublishStatus
} from '../api/Publish';

const apiVersion = 6;

export type SubscribeStatus = 'ok' | 'no-stream' | 'not-found' | 'unauthorized' | 'geo-restricted' | 'geo-blocked' | 'rate-limited' | 'capacity' | 'timeout' | 'failed';
export type SetRemoteDescriptionStatus = 'ok' | 'not-found' | 'unauthorized' | 'rate-limited' | 'capacity' | 'timeout' | 'failed';
export type SetTemporaryMaximalBitrateStatus = 'ok' | 'not-found' | 'unauthorized' | 'rate-limited' | 'capacity' | 'timeout' | 'failed';
export type RemoveTemporaryMaximalBitrateStatus = 'ok' | 'not-found' | 'unauthorized' | 'rate-limited' | 'capacity' | 'timeout' | 'failed';
export type AddIceCandidatesStatus = 'ok' | 'not-found' | 'unauthorized' | 'rate-limited' | 'capacity' | 'timeout' | 'failed';
export type DestroyStreamStatus = 'ok' | 'not-found' | 'unauthorized' | 'rate-limited' | 'capacity' | 'timeout' | 'failed';
export interface IStream {
  streamId: string;
  sharedSecret: string;
  tenancy: string;
}

export interface ISubscribeResponseInit {
  status: SubscribeStatus;
  stream?: IStream;
  lag?: Millisecond;
  rtcConfiguration?: RTCConfiguration;
  setRemoteDescriptionResponse?: {
    sessionDescription: RTCSessionDescriptionInit;
  };
  createOfferDescriptionResponse?: {
    sessionDescription: RTCSessionDescriptionInit;
  };
  createAnswerDescriptionResponse?: {
    sessionDescription: RTCSessionDescriptionInit;
  };
}

export type IPublishResponseInit = ISubscribeResponseInit;

interface ISetRemoteDescriptionResponseInit {
  status: SetRemoteDescriptionStatus;
  sessionDescription?: RTCSessionDescriptionInit;
}

interface IAddIceCandidatesResponseInit {
  status: AddIceCandidatesStatus;
  options?: string[];
}

interface ISetTemporaryMaximalBitrateResponseInit {
  status: SetTemporaryMaximalBitrateStatus;
}

interface IDestroyStreamResponseInit {
  status: DestroyStreamStatus;
}

export default class EndPoint {
  private readonly _logger: ILogger = LoggerFactory.getLogger('EndPoint');
  private readonly _uri: string;
  private readonly _timeout: number;
  private _roundTripTime: number;

  constructor(uri: string, timeout: number) {
    this._uri = uri;
    this._timeout = timeout;

    if (!timeout) {
      throw new Error(`End point requires a timeout`);
    }
  }

  get roundTripTime(): number {
    return this._roundTripTime;
  }

  toString(): string {
    return `EndPoint[uri=${this._uri}]`;
  }

  async ping(): Promise<number> {
    const url = this.buildPingUrl();
    const start = Date.now();
    const response = await Promise.race([
      fetch(url, {
        method: 'GET',
        cache: 'no-cache'
      }),
      new Promise<Response>((_, reject) =>
        setTimeout(() => reject(new Error(`Ping timed out [${url}]`)), this._timeout)
      )
    ]);
    const finished = Date.now();

    if (!response.ok) { /* Handle */
      throw new Error(`Ping failed [${url}] [${response.status}]`);
    }

    this._roundTripTime = finished - start;

    return this._roundTripTime;
  }

  async subscribe(token: string, localSessionDescription: RTCSessionDescriptionInit, failureCount: number): Promise<ISubscribeResponseInit> {
    const parsedToken = EdgeAuth.parseToken(token);

    if (!parsedToken || !parsedToken.applicationId) {
      this._logger.error('Failed to parse token [%s]', token);

      return {status: 'unauthorized'};
    }

    const tenancy = parsedToken.applicationId;
    const url = this.buildUrl([tenancy, 'stream', 'subscribe']).toString();
    const formData = new FormData();
    const clientVersion = VersionManager.sdkVersion;

    if (failureCount === 0 && localSessionDescription) {
      const bodyWithOffer: ISubscribeWithOfferRequest = {
        apiVersion,
        clientVersion,
        edgeAuthToken: token,
        failureCount,
        httpRoundTripTime: this._roundTripTime,
        setRemoteDescription: {
          apiVersion,
          sessionDescription: {
            type: this.convertRTCSdpTypeToSdpType(localSessionDescription.type),
            sdp: localSessionDescription.sdp
          }
        },
        createAnswerDescription: {apiVersion}
      };

      formData.append('jsonBody', JSON.stringify(bodyWithOffer));
    } else {
      const bodyWithoutOffer: ISubscribeWithoutOfferRequest = {
        apiVersion,
        clientVersion,
        edgeAuthToken: token,
        failureCount,
        httpRoundTripTime: this._roundTripTime,
        createOfferDescription: {apiVersion}
      };

      formData.append('jsonBody', JSON.stringify(bodyWithoutOffer));
    }

    const start = Date.now();
    let httpResponse: Response;

    try {
      let timeout = null;

      httpResponse = await Promise.race([
        fetch(url, {
          method: 'POST',
          body: formData
        }),
        new Promise<Response>(resolve =>
          timeout = window.setTimeout(() => {
            this._logger.error('Failed to subscribe', new Error(`Subscribe timed out [${url}]`));
            resolve({status: 408} as Response);
          }, this._timeout)
        )
      ])
        .finally(() => {
          if (timeout) {
            clearTimeout(timeout);
          }
        });
    } catch (e) {
      this._logger.error('Failed to subscribe', e);

      return {status: 'failed'};
    }

    const status: SubscribeStatus = this.mapHttpStatusToSubscribeStatus(httpResponse);

    if (status !== 'ok') {
      return {status};
    }

    const finished = Date.now();
    const subscribeResponse = await this.convertHttpResponseToSubscribeResponse(tenancy, httpResponse);

    this._logger.debug('Got subscribe response [%j] in [%s] ms', subscribeResponse, finished - start);

    return subscribeResponse;
  }

  async publish(name: string, token: string, localSessionDescription: RTCSessionDescriptionInit, failureCount: number): Promise<IPublishResponseInit> {
    const parsedToken = EdgeAuth.parseToken(token);

    if (!parsedToken || !parsedToken.applicationId) {
      this._logger.error('Failed to parse token [%s]', token);

      return {status: 'unauthorized'};
    }

    const tenancy = parsedToken.applicationId;
    const url = this.buildUrl([tenancy, 'stream', 'publish']).toString();
    const formData = new FormData();
    const clientVersion = VersionManager.sdkVersion;

    if (failureCount === 0 && localSessionDescription) {
      const bodyWithOffer: IPublishWithOfferRequest = {
        apiVersion,
        clientVersion,
        edgeAuthToken: token,
        failureCount,
        httpRoundTripTime: this._roundTripTime,
        name,
        setRemoteDescription: {
          apiVersion,
          sessionDescription: {
            type: this.convertRTCSdpTypeToSdpType(localSessionDescription.type),
            sdp: localSessionDescription.sdp
          }
        },
        createAnswerDescription: {
          streamId: '',
          options: ['upload'],
          apiVersion
        }
      };

      formData.append('jsonBody', JSON.stringify(bodyWithOffer));
    } else {
      const bodyWithoutOffer: IPublishWithoutOfferRequest = {
        apiVersion,
        clientVersion,
        edgeAuthToken: token,
        failureCount,
        httpRoundTripTime: this._roundTripTime,
        name,
        createOfferDescription: {
          streamId: '',
          options: ['upload'],
          apiVersion
        }
      };

      formData.append('jsonBody', JSON.stringify(bodyWithoutOffer));
    }

    const start = Date.now();
    let httpResponse: Response;

    try {
      let timeout = null;

      httpResponse = await Promise.race([
        fetch(url, {
          method: 'POST',
          body: formData
        }),
        new Promise<Response>(resolve =>
          timeout = window.setTimeout(() => {
            this._logger.error('Failed to publish', new Error(`Publish timed out [${url}]`));
            resolve({status: 408} as Response);
          }, this._timeout)
        )
      ])
        .finally(() => {
          if (timeout) {
            clearTimeout(timeout);
          }
        });
    } catch (e) {
      this._logger.error('Failed to publish', e);

      return {status: 'failed'};
    }

    const status: PublishStatus = this.mapHttpStatusToPublishStatus(httpResponse);

    if (status !== 'ok') {
      return {status};
    }

    const finished = Date.now();
    const subscribeResponse = await this.convertHttpResponseToPublishResponse(tenancy, httpResponse);

    this._logger.debug('Got publish response [%j] in [%s] ms', subscribeResponse, finished - start);

    return subscribeResponse;
  }

  async setRemoteDescription(stream: IStream, sessionDescription: RTCSessionDescriptionInit): Promise<ISetRemoteDescriptionResponseInit> {
    const url = this.buildUrl([stream.tenancy, 'stream', stream.streamId, 'description', 'remote']).toString();
    const formData = new FormData();
    const body: ISetRemoteDescriptionRequest = {
      apiVersion,
      sharedSecret: stream.sharedSecret,
      sessionDescription: {
        type: this.convertRTCSdpTypeToSdpType(sessionDescription.type),
        sdp: sessionDescription.sdp
      }
    };

    formData.append('jsonBody', JSON.stringify(body));

    const start = Date.now();
    let httpResponse: Response;

    try {
      let timeout = null;

      httpResponse = await Promise.race([
        fetch(url, {
          method: 'POST',
          body: formData
        }),
        new Promise<Response>(resolve =>
          timeout = window.setTimeout(() => {
            this._logger.error('Failed to set remote description', new Error(`Set remote description timed out [${url}]`));
            resolve({status: 408} as Response);
          }, this._timeout)
        )
      ])
        .finally(() => {
          if (timeout) {
            clearTimeout(timeout);
          }
        });
    } catch (e) {
      this._logger.error('Failed to set remote description', e);

      return {status: 'failed'};
    }

    const status: SetRemoteDescriptionStatus = this.mapHttpStatusToSetRemoteDescriptionStatus(httpResponse);

    if (status !== 'ok') {
      return {status};
    }

    const finished = Date.now();
    const setRemoteDescriptionResponse = await this.convertHttpResponseToSetRemoteDescriptionResponse(httpResponse);

    this._logger.debug('Got set remote description response [%j] in [%s] ms', setRemoteDescriptionResponse, finished - start);

    return setRemoteDescriptionResponse;
  }

  async limitBitrate(
    stream: IStream,
    elapsedInMilliseconds: number,
    bitrateInBitsPerSecond: BitsPerSecond,
    bitrateState: BitrateState,
    bitrateMode: BitrateMode,
  ): Promise<ISetTemporaryMaximalBitrateResponseInit> {
    const url = this.buildUrl([stream.tenancy, 'stream', stream.streamId, 'bitrate']).toString();
    const formData = new FormData();
    const body: ISetTemporaryMaximalBitrate = {
      apiVersion,
      sharedSecret: stream.sharedSecret,
      elapsedInMilliseconds,
      bitrateInBitsPerSecond,
      bitrateState: BitrateStateMapping.convertBitrateStateToBitrateStateType(bitrateState),
      bitrateMode: BitrateModeMapping.convertBitrateModeToBitrateModeType(bitrateMode)
    };

    formData.append('jsonBody', JSON.stringify(body));

    const start = Date.now();
    let httpResponse: Response;

    try {
      let timeout = null;

      httpResponse = await Promise.race([
        fetch(url, {
          method: 'POST',
          body: formData
        }),
        new Promise<Response>(resolve =>
          timeout = window.setTimeout(() => {
            this._logger.error('Failed to set limit bitrate timed', new Error(`Set limit bitrate timed out [${url}]`));
            resolve({status: 408} as Response);
          }, this._timeout)
        )
      ])
        .finally(() => {
          if (timeout) {
            clearTimeout(timeout);
          }
        });
    } catch (e) {
      this._logger.error('Failed to set limit bitrate timed', e);

      return {status: 'failed'};
    }

    const status: SetTemporaryMaximalBitrateStatus = this.mapHttpStatusToSetTemporaryMaximalBitrateStatus(httpResponse);
    const finished = Date.now();

    this._logger.info('Got set limit bitrate response [%s] in [%s] ms', status, finished - start);

    return {status};
  }

  async addIceCandidates(stream: IStream, candidates: RTCIceCandidate[], discoveryCompleted: boolean, options: string[] = []): Promise<IAddIceCandidatesResponseInit> {
    const url = this.buildUrl([stream.tenancy, 'stream', stream.streamId, 'ice', 'candidates']).toString();
    const formData = new FormData();
    const body: IAddIceCandidatesRequest = {
      apiVersion,
      sharedSecret: stream.sharedSecret,
      candidates,
      discoveryCompleted,
      options
    };

    formData.append('jsonBody', JSON.stringify(body));

    const start = Date.now();
    let httpResponse: Response;

    try {
      let timeout = null;

      httpResponse = await Promise.race([
        fetch(url, {
          method: 'POST',
          body: formData
        }),
        new Promise<Response>(resolve =>
          timeout = window.setTimeout(() => {
            this._logger.error('Failed to add ice candidates', new Error(`Add ice candidates timed out [${url}]`));
            resolve({status: 408} as Response);
          }, this._timeout)
        )
      ])
        .finally(() => {
          if (timeout) {
            clearTimeout(timeout);
          }
        });
    } catch (e) {
      this._logger.error('Failed to add ice candidates', e);

      return {status: 'failed'};
    }

    const status: AddIceCandidatesStatus = this.mapHttpStatusToAddIceCandidatesStatus(httpResponse);

    if (status !== 'ok') {
      return {status};
    }

    const finished = Date.now();
    const addIceCandidatesResponse = await this.convertHttpResponseToAddIceCandidatesResponse(httpResponse);

    this._logger.info('Got add ICE candidates response [%j] in [%s] ms', addIceCandidatesResponse, finished - start);

    return addIceCandidatesResponse;
  }

  async destroyStream(stream: IStream, reason: string): Promise<IDestroyStreamResponseInit> {
    const url = this.buildUrl([stream.tenancy, 'stream', stream.streamId, 'destroy']).toString();
    const formData = new FormData();
    const body: IDestroyStreamRequest = {
      apiVersion,
      sharedSecret: stream.sharedSecret,
      reason,
      options: []
    };

    formData.append('jsonBody', JSON.stringify(body));

    const start = Date.now();
    let httpResponse: Response;

    try {
      let timeout = null;

      httpResponse = await Promise.race([
        fetch(url, {
          method: 'POST',
          body: formData,
          cache: 'no-cache'
        }),
        new Promise<Response>(resolve =>
          timeout = window.setTimeout(() => {
            this._logger.error('Failed to subscribe', new Error(`Delete stream timed out [${url}]`));
            resolve({status: 408} as Response);
          }, this._timeout)
        )
      ])
        .finally(() => {
          if (timeout) {
            clearTimeout(timeout);
          }
        });
    } catch (e) {
      this._logger.error('Failed to delete stream', e);

      return {status: 'failed'};
    }

    const status: DestroyStreamStatus = this.mapHttpStatusToSetDestroyStreamStatus(httpResponse);

    if (status !== 'ok') {
      return {status};
    }

    const finished = Date.now();
    const destroyStreamResponse = await this.convertHttpResponseToDestroyStreamResponse(httpResponse);

    this._logger.info('Got destroy stream response [%j] in [%s] ms', destroyStreamResponse, finished - start);

    return destroyStreamResponse;
  }

  buildUrl(path: string[]): URL {
    const uri = new URL(this._uri);
    const pathAsArray = uri.pathname.split('/');

    pathAsArray.length = pathAsArray.length - 1;

    uri.pathname = pathAsArray.concat(...path).join('/');

    return uri;
  }

  private buildPingUrl(): string {
    const uri = new URL(this._uri);
    const sdkVersion = VersionManager.sdkVersion;

    uri.search = `?${new URLSearchParams([['type', 'http'], ['version', sdkVersion], ['_', `${Date.now()}`]]).toString()}`;

    return uri.toString();
  }

  private mapHttpStatusToPublishStatus(response: Response): PublishStatus {
    if (!response) {
      return 'failed';
    }

    switch (response.status) {
      case 200:
        return 'ok';
      case 401:
        return 'unauthorized';
      case 402:
        return 'geo-restricted';
      case 403:
        return 'geo-blocked';
      case 408:
        return 'timeout';
      case 503:
        return 'capacity';
      case 504:
        return 'rate-limited';
      default:
        return 'failed';
    }
  }

  private mapHttpStatusToSubscribeStatus(response: Response): SubscribeStatus {
    if (!response) {
      return 'failed';
    }

    switch (response.status) {
      case 200:
        return 'ok';
      case 401:
        return 'unauthorized';
      case 402:
        return 'geo-restricted';
      case 403:
        return 'geo-blocked';
      case 408:
        return 'timeout';
      case 503:
        return 'capacity';
      case 504:
        return 'rate-limited';
      default:
        return 'failed';
    }
  }

  private mapHttpStatusToSetRemoteDescriptionStatus(response: Response): SetRemoteDescriptionStatus {
    if (!response) {
      return 'failed';
    }

    switch (response.status) {
      case 200:
        return 'ok';
      case 401:
        return 'unauthorized';
      case 408:
        return 'timeout';
      case 503:
        return 'capacity';
      case 504:
        return 'rate-limited';
      default:
        return 'failed';
    }
  }

  private mapHttpStatusToSetTemporaryMaximalBitrateStatus(response: Response): SetTemporaryMaximalBitrateStatus {
    if (!response) {
      return 'failed';
    }

    switch (response.status) {
      case 200:
        return 'ok';
      case 401:
        return 'unauthorized';
      case 408:
        return 'timeout';
      case 503:
        return 'capacity';
      case 504:
        return 'rate-limited';
      default:
        return 'failed';
    }
  }

  private mapHttpStatusToAddIceCandidatesStatus(response: Response): AddIceCandidatesStatus {
    if (!response) {
      return 'failed';
    }

    switch (response.status) {
      case 200:
        return 'ok';
      case 401:
        return 'unauthorized';
      case 408:
        return 'timeout';
      case 503:
        return 'capacity';
      case 504:
        return 'rate-limited';
      default:
        return 'failed';
    }
  }

  private mapHttpStatusToSetDestroyStreamStatus(response: Response): DestroyStreamStatus {
    if (!response) {
      return 'failed';
    }

    switch (response.status) {
      case 200:
        return 'ok';
      case 401:
        return 'unauthorized';
      case 408:
        return 'timeout';
      case 503:
        return 'capacity';
      case 504:
        return 'rate-limited';
      default:
        return 'failed';
    }
  }

  private async convertHttpResponseToSubscribeResponse(tenancy: string, response: Response): Promise<ISubscribeResponseInit> {
    const data = await response.json() as ISubscribeResponse;
    const subscribeResponse: ISubscribeResponseInit = {status: data.status};

    subscribeResponse.stream = {
      tenancy,
      streamId: data.streamId,
      sharedSecret: data.sharedSecret
    };

    subscribeResponse.lag = data.lag;

    if (data) {
      if (data.rtcConfiguration) {
        subscribeResponse.rtcConfiguration = this.convertIRtcConfigurationToRTCConfiguration(data.rtcConfiguration);
      }

      if (data.setRemoteDescriptionResponse && data.setRemoteDescriptionResponse.sessionDescription) {
        subscribeResponse.setRemoteDescriptionResponse = {sessionDescription: this.convertISessionDescriptionToRTCSessionDescription(data.setRemoteDescriptionResponse.sessionDescription)};
      }

      if (data.createAnswerDescriptionResponse && data.createAnswerDescriptionResponse.sessionDescription) {
        subscribeResponse.createAnswerDescriptionResponse = {sessionDescription: this.convertISessionDescriptionToRTCSessionDescription(data.createAnswerDescriptionResponse.sessionDescription)};
      }

      if (data.createOfferDescriptionResponse && data.createOfferDescriptionResponse.sessionDescription) {
        subscribeResponse.createOfferDescriptionResponse = {sessionDescription: this.convertISessionDescriptionToRTCSessionDescription(data.createOfferDescriptionResponse.sessionDescription)};
      }
    }

    return subscribeResponse;
  }

  private async convertHttpResponseToPublishResponse(tenancy: string, response: Response): Promise<IPublishResponseInit> {
    const data = await response.json() as IPublishResponse;
    const publishResponse: IPublishResponseInit = {status: data.status};

    publishResponse.stream = {
      tenancy,
      streamId: data.streamId,
      sharedSecret: data.sharedSecret
    };

    if (data) {
      if (data.rtcConfiguration) {
        publishResponse.rtcConfiguration = this.convertIRtcConfigurationToRTCConfiguration(data.rtcConfiguration);
      }

      if (data.setRemoteDescriptionResponse && data.setRemoteDescriptionResponse.sessionDescription) {
        publishResponse.setRemoteDescriptionResponse = {sessionDescription: this.convertISessionDescriptionToRTCSessionDescription(data.setRemoteDescriptionResponse.sessionDescription)};
      }

      if (data.createAnswerDescriptionResponse && data.createAnswerDescriptionResponse.sessionDescription) {
        publishResponse.createAnswerDescriptionResponse = {sessionDescription: this.convertISessionDescriptionToRTCSessionDescription(data.createAnswerDescriptionResponse.sessionDescription)};
      }

      if (data.createOfferDescriptionResponse && data.createOfferDescriptionResponse.sessionDescription) {
        publishResponse.createOfferDescriptionResponse = {sessionDescription: this.convertISessionDescriptionToRTCSessionDescription(data.createOfferDescriptionResponse.sessionDescription)};
      }
    }

    return publishResponse;
  }

  private convertIRtcConfigurationToRTCConfiguration(configuration: IRtcConfiguration): RTCConfiguration {
    const rtcConfiguration: RTCConfiguration = {};

    if (configuration.bundlePolicy) {
      switch (configuration.bundlePolicy) {
        case 'BundlePolicyBalanced':
          rtcConfiguration.bundlePolicy = 'balanced';

          break;
        case 'BundlePolicyMaxCompat':
          rtcConfiguration.bundlePolicy = 'max-compat';

          break;
        case 'BundlePolicyMaxBundle':
          rtcConfiguration.bundlePolicy = 'max-bundle';

          break;
        default:
          assertUnreachable(configuration.bundlePolicy);
      }
    }

    if (typeof configuration.iceCandidatePoolSize === 'number') {
      rtcConfiguration.iceCandidatePoolSize = configuration.iceCandidatePoolSize;
    }

    if (configuration.iceServers) {
      const iceServers: RTCIceServer[] = [];

      for (let i = 0; i < configuration.iceServers.length; i++) {
        iceServers.push({
          urls: configuration.iceServers[i].urls,
          username: configuration.iceServers[i].username,
          credential: configuration.iceServers[i].credential
        });
      }

      rtcConfiguration.iceServers = iceServers;
    }

    if (configuration.iceTransportPolicy) {
      switch (configuration.iceTransportPolicy) {
        case 'IceTransportPolicyAll':
          rtcConfiguration.iceTransportPolicy = 'all';

          break;
        case 'IceTransportPolicyRelay':
          rtcConfiguration.iceTransportPolicy = 'relay';

          break;
        case 'IceTransportPolicyPublic':
          // Deprecated - Not supported
          break;
        default:
          assertUnreachable(configuration.iceTransportPolicy);
      }
    }

    if (configuration.peerIdentity) {
      rtcConfiguration.peerIdentity = configuration.peerIdentity;
    }

    if (configuration.rtcpMuxPolicy) {
      switch (configuration.rtcpMuxPolicy) {
        case 'RtcpMuxPolicyNegotiate':
          rtcConfiguration.rtcpMuxPolicy = 'negotiate';

          break;
        case 'RtcpMuxPolicyRequire':
          rtcConfiguration.rtcpMuxPolicy = 'require';

          break;
        default:
          assertUnreachable(configuration.rtcpMuxPolicy);
      }
    }

    return rtcConfiguration;
  }

  private convertISessionDescriptionToRTCSessionDescription(sessionDescription: ISessionDescription): RTCSessionDescriptionInit {
    const rtcSessionDescription: RTCSessionDescriptionInit = {sdp: sessionDescription.sdp};

    switch (sessionDescription.type) {
      case 'Offer':
        rtcSessionDescription.type = 'offer';

        break;
      case 'Answer':
        rtcSessionDescription.type = 'answer';

        break;
      default:
        assertUnreachable(sessionDescription.type);
    }

    return rtcSessionDescription;
  }

  private async convertHttpResponseToSetRemoteDescriptionResponse(response: Response): Promise<ISetRemoteDescriptionResponseInit> {
    const data = await response.json() as ISetRemoteDescriptionResponse;
    const setRemoteDescriptionResponse: ISetRemoteDescriptionResponseInit = {status: data.status};

    if (data && data.sessionDescription) {
      setRemoteDescriptionResponse.sessionDescription = this.convertISessionDescriptionToRTCSessionDescription(data.sessionDescription);
    }

    return setRemoteDescriptionResponse;
  }

  private async convertHttpResponseToAddIceCandidatesResponse(response: Response): Promise<IAddIceCandidatesResponseInit> {
    const data = await response.json() as IAddIceCandidatesResponse;
    const addIceCandidatesResponse: IAddIceCandidatesResponseInit = {
      status: data.status,
      options: []
    };

    if (data) {
      if (data.options) {
        addIceCandidatesResponse.options = data.options;
      }
    }

    return addIceCandidatesResponse;
  }

  private async convertHttpResponseToDestroyStreamResponse(response: Response): Promise<IDestroyStreamResponseInit> {
    const data = await response.json() as IDestroyStreamResponse;
    const destroyStream: IDestroyStreamResponseInit = {status: data.status};

    return destroyStream;
  }

  private convertRTCSdpTypeToSdpType(type: RTCSdpType): SdpType {
    switch (type) {
      case 'answer':
        return 'Answer';
      case 'offer':
        return 'Offer';
      case 'pranswer':
      case 'rollback':
        throw new Error(`SDP type [${type}] is not supported`);
      default:
        assertUnreachable(type);
    }
  }
}