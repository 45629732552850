/**
 * Copyright 2022 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
declare const __SDKVERSION__: string;

export default class VersionManager {
  private static _defaultVersion = new Date().toISOString();

  static get sdkVersion(): string {
    try {
      return __SDKVERSION__;
    } catch (e) {
      return this._defaultVersion;
    }
  }
}