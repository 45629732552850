/**
 * Copyright 2022 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */

export type BitrateStateType = 'Keep' | 'Reset';
export type BitrateModeType = 'Normal' | 'Force' | 'Reset';

export enum BitrateState {
  Keep,
  Reset
}

export enum BitrateMode {
  Normal,
  Force,
  Reset
}

export interface ISetTemporaryMaximalBitrate {
  apiVersion: number;
  sharedSecret: string;
  elapsedInMilliseconds: number;
  bitrateInBitsPerSecond: number;
  bitrateState: BitrateStateType;
  bitrateMode: BitrateModeType;
}