/**
 * Copyright 2022 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import LoggerFactory from '../logger/LoggerFactory';
import SDK from '../sdk/SDK';
import IPeerConnection from './IPeerConnection';
import {ILogger} from '../logger/LoggerInterface';
import FeatureEnablement from '../sdk/feature/FeatureEnablement';

export interface IPeerConnectionOfferInit {
  audioTransceiver?: RTCRtpTransceiver;
  videoTransceiver?: RTCRtpTransceiver;
  peerConnection: IPeerConnection;
  localOffer: RTCSessionDescriptionInit;
}

export default class PeerConnectionService {
  private static _logger: ILogger = LoggerFactory.getLogger('PeerConnectionService');
  private static _cached: {
    direction?: RTCRtpTransceiverDirection;
    peerConnection: Promise<IPeerConnectionOfferInit>;
  };

  static initiateInitialPrecaching(): void {
    if (FeatureEnablement.isPrecachingEnabled) {
      const ignored = PeerConnectionService.precacheCreatePeerConnectionOffer()
        .catch(e => PeerConnectionService._logger.error('Failed to pre-cache peer connection offer"', e));
    }
  }

  static async precacheCreatePeerConnectionOffer(): Promise<{ peerConnection: Promise<IPeerConnectionOfferInit | void> }> {
    return PeerConnectionService._cached = {
      direction: 'recvonly',
      peerConnection: PeerConnectionService.createPeerConnectionOffer()
        .catch(e => {
          PeerConnectionService._cached = null;

          throw e;
        })
    };
  }

  static async createPeerConnectionOffer(direction: RTCRtpTransceiverDirection = 'recvonly'): Promise<IPeerConnectionOfferInit> {
    if (PeerConnectionService._cached && PeerConnectionService._cached.direction === direction) {
      const value = PeerConnectionService._cached.peerConnection;

      PeerConnectionService._cached = null;

      return value;
    }

    const peerConnectionFactory = SDK.peerConnectionFactory.value;

    return peerConnectionFactory.createPeerConnection()
      .then(async peerConnection => {
        const supportsAddTransceiver = peerConnection.supportsAddTransceiver;
        let videoTransceiver, audioTransceiver;

        if (supportsAddTransceiver) {
          audioTransceiver = peerConnection.addTransceiver('audio', {direction});
          videoTransceiver = peerConnection.addTransceiver('video', {direction});

          return {
            audioTransceiver,
            videoTransceiver,
            peerConnection: peerConnection,
            localOffer: await peerConnection.createOffer(undefined)
          };
        }

        const options = direction === 'recvonly' ? {
          offerToReceiveAudio: true,
          offerToReceiveVideo: true
        } : {};

        return {
          peerConnection: peerConnection,
          localOffer: await peerConnection.createOffer(options)
        };
      });
  }

  private constructor() {
    throw new Error('PeerConnectionService is a static class that may not be instantiated');
  }
}

PeerConnectionService.initiateInitialPrecaching();