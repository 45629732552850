/**
 * Copyright 2022 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import LoggerFactory from '../../logger/LoggerFactory';
import EndPoint from './EndPoint';
import SDK from '../SDK';
import {ILogger} from '../../logger/LoggerInterface';
import MetricsType from '../../metrics/MetricsType';
import DiscoveryUri from './DiscoveryUri';
import MetricsService from '../../metrics/MetricsService';
import MetricsFactory from '../../metrics/MetricsFactory';

const defaultTimeout = 20000;

export default class DiscoveryService {
  private _logger: ILogger = LoggerFactory.getLogger('Discovery');
  private _metricsService: MetricsService;
  private _uri: URL;

  constructor(uri: URL) {
    if (!uri) {
      throw new Error('Discovery requires uri');
    }

    this._metricsService = MetricsFactory.getMetricsService(uri.toString());
    this._uri = uri;
  }

  async discoverNearbyEndPoints(uri: URL, timeout: number): Promise<EndPoint[]> {
    if (!timeout) {
      throw new Error('Discovery requires timeout');
    }

    const url = uri.toString();
    const response = await Promise.race([
      fetch(url, {
        method: 'GET',
        cache: 'no-cache'
      }),
      new Promise<Response>((_, reject) =>
        setTimeout(() => reject(new Error(`Discovery timed out [${url}]`)), timeout)
      )
    ]);

    if (!response.ok) {
      throw new Error(`Discovery failed [${url}] [${response.status}]`);
    }

    if (response.body === null) {
      throw new Error(`Discovery failed with no data [${url}]`);
    }

    const asString = await response.text();
    const endPoints = asString.split(',');

    return endPoints.map(endPoint => new EndPoint(endPoint, timeout));
  }

  async discoverClosestEndPoint(timeout: number = defaultTimeout): Promise<EndPoint> {
    const url = DiscoveryUri.buildDiscoveryUrl(this._uri);
    const endPoints = await this.discoverNearbyEndPoints(new URL(url), timeout);
    const neverResolve = (): Promise<void> => new Promise(() => {
      this._logger.info('Request [%s] failed, preventing it from completing', url);
    });
    const endPoint = await Promise.race(endPoints.map(endPoint => endPoint
      .ping()
      .catch(e => {
        this._logger.warn('Failed to ping end point [%s]', endPoint, e);

        return neverResolve();
      })
      .then(time => {
        const now = Date.now();

        this._logger.info('Discovered end point [%s] with time [%s]', endPoint.toString(), time);
        this._metricsService.push({
          metricType: MetricsType.RoundTripTime,
          runtime: (now - SDK.pageLoadTime) / 1000,
          value: {uint64: time || 0},
          resource: endPoint.toString(),
          kind: 'ping'
        });

        return endPoint;
      })));

    return endPoint;
  }
}