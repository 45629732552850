/**
 * Copyright 2022 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import LoggerFactory from '../../logger/LoggerFactory';
import {IEdgeAuth} from './EdgeAuthToken';
import {ILogger} from '../../logger/LoggerInterface';
import {EdgeToken} from './EdgeToken';

const edgeAuthTokenPrefix = 'DIGEST:';

export default class EdgeAuth {
  private static _logger: ILogger = LoggerFactory.getLogger('EdgeAuth');

  static parseToken(token: EdgeToken): IEdgeAuth {
    if (!this.isValidToken(token)) {
      EdgeAuth._logger.error('Token is not valid [%s]', token);

      return undefined;
    }

    try {
      const encodedEdgeAuthToken = atob(token.substr(edgeAuthTokenPrefix.length));
      const edgeAuthToken = JSON.parse(encodedEdgeAuthToken);

      edgeAuthToken.token = JSON.parse(edgeAuthToken.token);

      return edgeAuthToken;
    } catch (e) {
      EdgeAuth._logger.error('Cannot parse token value', e);

      return undefined;
    }
  }

  static getUri(parsedToken: IEdgeAuth): URL {
    return parsedToken && parsedToken.token && parsedToken.token.uri ?
      new URL(parsedToken.token.uri) : null;
  }

  static getTenancy(parsedToken: IEdgeAuth): string {
    return parsedToken && parsedToken.applicationId ?
      parsedToken.applicationId : '';
  }

  static isValidToken(token: EdgeToken): boolean {
    return !!token && token.startsWith(edgeAuthTokenPrefix);
  }
}