/**
 * Copyright 2022 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
enum MetricsType {
  TimeToFirstFrame,
  ChannelCreationTimeToFirstFrame,
  Stalled,
  Buffering,
  SetupCompleted,
  SetupFailed,
  RoundTripTime,
  DownlinkThroughputCapacity,
  NetworkType,
  ResolutionChanged,
  ApplicationForeground,
  ApplicationBackground
}

export default MetricsType;