/**
 * Copyright 2022 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import FeatureEnablement from '../feature/FeatureEnablement';
import WebPlayer from './WebPlayer';
import SDK from '../SDK';
import ShakaPlayer from './ShakaPlayer';
import HlsPlayer from './HlsPlayer';
import IDisposable from '../../lang/IDisposable';
import {HlsPlayerType, LiveStreamingOptions, LiveStreamingStatistics, ShakaType, WebPlayerType} from './StreamTypes';

export class LiveStreaming {
  private static _pending = false;
  private static _hlsPlayer: HlsPlayerType;
  private static _phenixWebPlayer: WebPlayerType;
  private static _shaka: ShakaType;
  private static _options: LiveStreamingOptions;
  private static _player: ShakaPlayer | WebPlayer | HlsPlayer;

  static get hlsPlayer(): HlsPlayerType {
    return this._hlsPlayer;
  }

  static get phenixWebPlayer(): WebPlayerType {
    return this._phenixWebPlayer;
  }

  static get shaka(): ShakaType {
    return this._shaka;
  }

  static async start(videoElement: HTMLVideoElement, streamId: string, offerSdp: string, options: LiveStreamingOptions): Promise<IDisposable> {
    this._options = options;

    if (this._hlsPlayer || this._shaka || this._phenixWebPlayer) {
      return this.loaded(videoElement, streamId, offerSdp);
    }

    if (!SDK.webPlayerLoader && !SDK.shakaPlayerLoader && !SDK.hlsJsLoader) {
      throw new Error('No player is provided');
    }

    if (!SDK.webPlayerLoader && !SDK.shakaPlayerLoader && !SDK.hlsJsLoader) {
      throw new Error('Unsupported-feature, add stream player');
    }

    return new Promise(resolve => {
      if (SDK.hlsJsLoader) {
        this._pending = true;
        SDK.hlsJsLoader(player => {
          this._hlsPlayer = player;

          if (this._pending) {
            this._pending = false;

            resolve(this.loaded(videoElement, streamId, offerSdp));
          }
        });
      }

      if (SDK.webPlayerLoader) {
        this._pending = true;
        SDK.webPlayerLoader(player => {
          this._phenixWebPlayer = player;

          if (this._pending) {
            this._pending = false;

            resolve(this.loaded(videoElement, streamId, offerSdp));
          }
        });
      }

      if (SDK.shakaPlayerLoader) {
        this._pending = true;
        SDK.shakaPlayerLoader(player => {
          this._shaka = player;

          if (this._pending) {
            this._pending = false;

            resolve(this.loaded(videoElement, streamId, offerSdp));
          }
        });
      }
    });
  }

  static getStats(): LiveStreamingStatistics {
    if (this._player) {
      return this._player.getStats();
    }

    return null;
  }

  private static play(Player: typeof WebPlayer | typeof ShakaPlayer | typeof HlsPlayer, videoElement: HTMLVideoElement, streamId: string, offerSdp: string, options: LiveStreamingOptions): IDisposable {
    const dashManifestUrlMatch = offerSdp.match(/a=x-playlist:([^\n]*[.]mpd\??[^\s]*)/m);
    const hlsManifestUrlMatch = offerSdp.match(/a=x-playlist:([^\n]*[.]m3u8\??[^\s]*)/m);
    const manifestUrl = dashManifestUrlMatch && dashManifestUrlMatch[1] ? dashManifestUrlMatch[1] : '';
    const playlistUrl = hlsManifestUrlMatch && hlsManifestUrlMatch[1] ? hlsManifestUrlMatch[1] : '';

    if (FeatureEnablement.shouldUseNativeHls || (this._hlsPlayer && this._hlsPlayer.isSupported())) {
      this._player = new Player(videoElement, 'hls', streamId, playlistUrl, options);

      this._player.start();

      return this._player;
    }

    this._player = new Player(videoElement, 'dash', streamId, manifestUrl, options);

    this._player.start();

    return this._player;
  }

  private static loaded(videoElement: HTMLVideoElement, streamId: string, offerSdp: string): IDisposable {
    if (!this._shaka && !this._phenixWebPlayer && !this._hlsPlayer) {
      return;
    }

    if (this._hlsPlayer) {
      return this.play(HlsPlayer, videoElement, streamId, offerSdp, {
        ...this._options,
        preferNative: FeatureEnablement.shouldUseNativeHls
      });
    }

    if (this._phenixWebPlayer) {
      return this.play(WebPlayer, videoElement, streamId, offerSdp, {
        ...this._options,
        preferNative: FeatureEnablement.shouldUseNativeHls
      });
    }

    if (this._shaka) {
      return this.play(ShakaPlayer, videoElement, streamId, offerSdp, {
        ...this._options,
        preferNative: FeatureEnablement.shouldUseNativeHls
      });
    }
  }
}