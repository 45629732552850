/**
 * Copyright 2022 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import {IStream} from './IStream';
import EdgeAuth from '../edgeAuth/EdgeAuth';
import ChunkedStream from './ChunkedStream';
import RealTimeStream from './RealTimeStream';
import {ILogger} from '../../logger/LoggerInterface';
import LoggerFactory from '../../logger/LoggerFactory';
import ChannelState from '../channels/ChannelState';

export default class StreamFactory {
  private static _logger: ILogger = LoggerFactory.getLogger('Streaming');

  static create(token: string, channelContext, handleStreamFailure: () => Promise<void>): IStream {
    const parsedToken = EdgeAuth.parseToken(token);
    const isRtmp = parsedToken && parsedToken.token && parsedToken.token.capabilities && parsedToken.token.capabilities.includes('rtmp');

    if (isRtmp) {
      this._logger.error('This build does not support RTMP playback.');
      channelContext.state.value = ChannelState.UnsupportedFeature;

      return;
    }

    const isStreaming = parsedToken && parsedToken.token && parsedToken.token.capabilities && parsedToken.token.capabilities.includes('streaming');

    if (isStreaming) {
      return new ChunkedStream(channelContext, handleStreamFailure);
    }

    return new RealTimeStream(channelContext, handleStreamFailure);
  }

  constructor() {
    throw new Error('StreamFactory is a static class that may not be instantiated');
  }
}