/**
 * Copyright 2022 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import LoggerFactory from '../logger/LoggerFactory';
import {ILogger} from '../logger/LoggerInterface';
import Durations from '../time/Duration';
import Disposable from '../lang/Disposable';
import IDisposable from '../lang/IDisposable';
import MetricsType from '../metrics/MetricsType';
import DisposableList from '../lang/DisposableList';
import MetricsService from '../metrics/MetricsService';

export default class VideoTelemetry implements IDisposable {
  private readonly _pageLoadTime: number;
  private readonly _channelStartTime: number;
  private readonly _logger: ILogger = LoggerFactory.getLogger('StreamTelemetry');
  private readonly _disposables: DisposableList = new DisposableList();
  private readonly _streamId: string;
  private readonly _metricsService: MetricsService;
  private _listenToFirstTime: () => void;
  private _listenForStall: () => void;
  private _listenForContinuation: (event) => void;
  private _timeToFirstFrame: number;
  private _channelCreationTimeToFirstFrame: number;
  private _startRecordingFirstFrame: number;
  private _videoStalled: number;
  private _lastProgress: number;
  private _videoResolution: string;
  private _clearTimeToFirstFrameListener: Disposable;
  private _clearRebufferingListener: Disposable;

  constructor(streamId: string, pageLoadTime: number, channelStartTime: number, metricsService: MetricsService) {
    this._streamId = streamId;
    this._pageLoadTime = pageLoadTime;
    this._channelStartTime = channelStartTime;

    this._metricsService = metricsService;
  }

  setupListenerForTimeToFirstTime(video: HTMLVideoElement): void {
    this._startRecordingFirstFrame = Date.now();

    this._listenToFirstTime = (): void => this.callTimeToFirstFrame();

    video.addEventListener('loadeddata', this._listenToFirstTime);
    video.addEventListener('loadedmetadata', this._listenToFirstTime);

    this._clearTimeToFirstFrameListener = new Disposable(() => {
      video.removeEventListener('loadeddata', this._listenToFirstTime);
      video.removeEventListener('loadedmetadata', this._listenToFirstTime);
    });
    this._disposables.add(this._clearTimeToFirstFrameListener);
  }

  setupListenerForRebuffering(video: HTMLVideoElement): void {
    this._listenForStall = (): void => this.onStall();

    this._listenForContinuation = (event): void => this.onContinuation(event, video);

    video.addEventListener('stalled', this._listenForStall);
    video.addEventListener('pause', this._listenForStall);
    video.addEventListener('suspend', this._listenForStall);
    video.addEventListener('play', this._listenForContinuation);
    video.addEventListener('playing', this._listenForContinuation);
    video.addEventListener('progress', this._listenForContinuation);
    video.addEventListener('timeupdate', this._listenForContinuation);

    this._clearRebufferingListener = new Disposable(() => {
      video.removeEventListener('stalled', this._listenForStall);
      video.removeEventListener('pause', this._listenForStall);
      video.removeEventListener('suspend', this._listenForStall);
      video.removeEventListener('play', this._listenForContinuation);
      video.removeEventListener('playing', this._listenForContinuation);
      video.removeEventListener('progress', this._listenForContinuation);
      video.removeEventListener('timeupdate', this._listenForContinuation);
    });

    this._disposables.add(this._clearRebufferingListener);
  }

  dispose(): void {
    this._disposables.dispose();
  }

  private get durationSincePageLoad(): string {
    const now = Date.now();

    return new Durations(now - this._pageLoadTime).toIsoString();
  }

  private callTimeToFirstFrame(): void {
    const now = Date.now();

    this.pushTimeToFirstFrame(now);
    this.pushChannelCreationTimeToFirstFrame(now);

    this._clearTimeToFirstFrameListener.dispose();
  }

  private pushTimeToFirstFrame(now): void {
    this._timeToFirstFrame = now - this._startRecordingFirstFrame;

    this._metricsService.push({
      metricType: MetricsType.TimeToFirstFrame,
      runtime: (now - this._pageLoadTime) / 1000,
      value: {uint64: this._timeToFirstFrame},
      streamId: this._streamId
    });

    this._logger.info(
      '[%s] [%s] First frame [%s]',
      this.durationSincePageLoad,
      this._streamId,
      new Durations(this._timeToFirstFrame).toIsoString()
    );
  }

  private pushChannelCreationTimeToFirstFrame(now): void {
    this._channelCreationTimeToFirstFrame = now - this._channelStartTime;

    this._metricsService.push({
      metricType: MetricsType.ChannelCreationTimeToFirstFrame,
      runtime: (now - this._pageLoadTime) / 1000,
      value: {uint64: this._channelCreationTimeToFirstFrame},
      streamId: this._streamId
    });

    this._logger.info(
      '[%s] [%s] Channel creation to first frame [%s]',
      this.durationSincePageLoad,
      this._streamId,
      new Durations(this._channelCreationTimeToFirstFrame).toIsoString()
    );
  }

  private onStall(): void {
    if (this._videoStalled) {
      return;
    }

    this._metricsService.push({
      metricType: MetricsType.Stalled,
      runtime: (Date.now() - this._pageLoadTime) / 1000,
      streamId: this._streamId
    });

    this._videoStalled = Date.now();

    this._logger.info(
      '[%s] [%s] [buffering] Stream has stalled',
      this.durationSincePageLoad,
      this._streamId
    );
  }

  private onContinuation(event, video): void {
    if (!video.buffered) {
      return;
    }

    const bufferLength = video.buffered.length;
    const hasNotProgressedSinceLastProgressEvent = event.type === 'playing'
    || bufferLength > 0 ? (event.type === 'progress'
      || event.type === 'timeupdate')
      && video.buffered.end(bufferLength - 1) === this._lastProgress : true;

    if (!this._videoStalled || hasNotProgressedSinceLastProgressEvent) {
      return;
    }

    if (event.type === 'progress') {
      this._lastProgress = video.buffered.end(bufferLength - 1);
    }

    const timeSinceStop = Date.now() - this._videoStalled;

    this._metricsService.push({
      metricType: MetricsType.Buffering,
      runtime: (Date.now() - this._pageLoadTime) / 1000,
      value: {uint64: timeSinceStop},
      streamId: this._streamId
    });

    this._logger.info(
      '[%s] [%s] [buffering] Stream has recovered from stall after [%s]',
      this.durationSincePageLoad,
      this._streamId,
      new Durations(timeSinceStop).toIsoString()
    );
    this._videoStalled = null;
  }

  onVideoResolutionChanges(newResolution: string): void {
    this._metricsService.push({
      metricType: MetricsType.ResolutionChanged,
      runtime: (Date.now() - this._pageLoadTime) / 1000,
      value: {string: newResolution},
      previousValue: this._videoResolution ? {string: this._videoResolution} : undefined,
      streamId: this._streamId
    });

    this._videoResolution = newResolution;
  }
}