/**
 * Copyright 2022 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import PrivacyMode from '../dom/PrivacyMode';
import {LoggingLevel} from '../logger/Logger';

export default class LoggerDefaults {
  static get defaultLoggingLevel(): LoggingLevel {
    return PrivacyMode.isPrivate ? LoggingLevel.Off : LoggingLevel.All;
  }

  static get defaultConsoleLoggingLevel(): LoggingLevel {
    return PrivacyMode.isPrivate ? LoggingLevel.Off : LoggingLevel.All;
  }

  static get defaultTelemetryLoggingLevel(): LoggingLevel {
    return LoggingLevel.Info;
  }
}