/**
 * Copyright 2022 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */

export default class Environment {
  static getEnvironmentFromUrl(url: string): string {
    try {
      const baseURL = new URL(url);

      return baseURL.origin;
    } catch (e) {
      return '';
    }
  }
}