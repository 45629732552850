/**
 * Copyright 2022 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import IDisposable from './IDisposable';

export default class Disposable implements IDisposable {
  private _disposed: boolean;
  private _disposable: () => void;

  constructor(disposable: () => void) {
    this._disposed = false;
    this._disposable = disposable;
  }

  dispose(): void {
    if (this._disposed) {
      return;
    }

    this._disposed = true;

    return this._disposable.call(this);
  }
}