/**
 * Copyright 2022 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import LoggerFactory from '../logger/LoggerFactory';
import IPeerConnection, {PeerConnectionEvents} from './IPeerConnection';
import {ILogger} from '../logger/LoggerInterface';
import FeatureEnablement from '../sdk/feature/FeatureEnablement';
import {ILegacyRTCStatsReport} from './RtcConnectionMonitor';

class VanillaPeerConnection implements IPeerConnection {
  private readonly _logger: ILogger = LoggerFactory.getLogger('VanillaPeerConnection');
  private readonly _peerConnection: RTCPeerConnection;

  constructor(configuration?: RTCConfiguration) {
    if (FeatureEnablement.webkitRTCPeerConnectionEnabled) {
      this._peerConnection = new webkitRTCPeerConnection(configuration);

      return;
    }

    this._peerConnection = new RTCPeerConnection(configuration);
  }

  get native(): RTCPeerConnection {
    return this._peerConnection;
  }

  get currentLocalDescription(): RTCSessionDescription | null {
    return this._peerConnection.currentLocalDescription;
  }

  get currentRemoteDescription(): RTCSessionDescription | null {
    return this._peerConnection.currentRemoteDescription;
  }

  get iceConnectionState(): RTCIceConnectionState {
    return this._peerConnection.iceConnectionState;
  }

  get supportsGetConfiguration(): boolean {
    return typeof this._peerConnection.getConfiguration === 'function';
  }

  get supportsSetConfiguration(): boolean {
    return typeof this._peerConnection.setConfiguration === 'function';
  }

  get supportsAddTransceiver(): boolean {
    return !FeatureEnablement.addTranceiverDisabled && typeof this._peerConnection.addTransceiver === 'function';
  }

  async createOffer(options?: RTCOfferOptions): Promise<RTCSessionDescriptionInit> {
    if (FeatureEnablement.promiseBasedPCMethodsDisabled) {
      // Ignored as leagcy createOffer method with callbacks is deprecated
      // Requires a ts-ignore directive to prevent typescript errors
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return new Promise((resolve, reject) => this._peerConnection.createOffer(resolve, reject, options));
    }

    return this._peerConnection.createOffer(options);
  }

  async createAnswer(options?: RTCAnswerOptions): Promise<RTCSessionDescriptionInit> {
    if (FeatureEnablement.promiseBasedPCMethodsDisabled) {
      // Ignored as leagcy createAnswer method with callbacks is deprecated
      // Requires a ts-ignore directive to prevent typescript errors
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return new Promise((resolve, reject) => this._peerConnection.createAnswer(resolve, reject, options));
    }

    return this._peerConnection.createAnswer(options);
  }

  async setLocalDescription(description: RTCSessionDescriptionInit): Promise<void> {
    return this._peerConnection.setLocalDescription(FeatureEnablement.promiseBasedPCMethodsDisabled ?
      new RTCSessionDescription(description) : description);
  }

  async setRemoteDescription(description: RTCSessionDescriptionInit): Promise<void> {
    const hasCrypto = description.sdp?.match(/a=crypto:/i);

    if (hasCrypto) {
      this._logger.warn('SDP a=crypto is not supported');
    }

    return this._peerConnection.setRemoteDescription(FeatureEnablement.promiseBasedPCMethodsDisabled ?
      new RTCSessionDescription(description) : description);
  }

  getStats(selector?: MediaStreamTrack | null): Promise<RTCStatsReport> {
    return this._peerConnection.getStats(selector);
  }

  getStatsLegacy(): Promise<ILegacyRTCStatsReport> {
    return new Promise(resolve => {
      // Ignored due to typescript not having legacy 'getStats' method type definition
      // This was a Chrome specific implementation of the 'getStats' method for
      // Chrome@<= 66 detailed here: https://bugs.chromium.org/p/chromium/issues/detail?id=627816
      // Requires a ts-ignore directive to prevent typescript errors
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const ignored = this._peerConnection.getStats(resolve);
    });
  }

  addEventListener(type: PeerConnectionEvents, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void {
    this._peerConnection.addEventListener(type, listener, options);
  }

  removeEventListener(type: PeerConnectionEvents, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void {
    this._peerConnection.removeEventListener(type, listener, options);
  }

  addTransceiver(name: string, options: RTCRtpTransceiverInit): RTCRtpTransceiver {
    return this._peerConnection.addTransceiver(name, options);
  }

  addStream(mediaStream: MediaStream): void {
    mediaStream.getTracks().forEach(track => {
      this._peerConnection.addTrack(track);
    });
  }

  getConfiguration(): RTCConfiguration {
    return this._peerConnection.getConfiguration();
  }

  setConfiguration(configuration: RTCConfiguration): void {
    this._peerConnection.setConfiguration(configuration);
  }

  set ontrack(handler: ((this: RTCPeerConnection, ev: RTCTrackEvent) => unknown) | null) {
    this._peerConnection.ontrack = handler;
  }

  get ontrack(): ((this: RTCPeerConnection, ev: RTCTrackEvent) => unknown) | null {
    return this._peerConnection.ontrack;
  }

  set onicecandidate(handler: ((this: RTCPeerConnection, ev: RTCPeerConnectionIceEvent) => unknown) | null) {
    this._peerConnection.onicecandidate = handler;
  }

  get onicecandidate(): ((this: RTCPeerConnection, ev: RTCPeerConnectionIceEvent) => unknown) | null {
    return this._peerConnection.onicecandidate;
  }

  set oniceconnectionstatechange(handler: ((this: RTCPeerConnection, ev: Event) => unknown) | null) {
    this._peerConnection.oniceconnectionstatechange = handler;
  }

  get oniceconnectionstatechange(): ((this: RTCPeerConnection, ev: Event) => unknown) | null {
    return this._peerConnection.oniceconnectionstatechange;
  }

  close(): void {
    this._peerConnection.close();
  }
}

export default VanillaPeerConnection;