/**
 * Copyright 2022 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */

import Subject from './Subject';
import IDisposable from '../lang/IDisposable';

export default class ReadOnlySubject<T> {
  private readonly _subject: Subject<T>;

  constructor(subject: Subject<T>) {
    this._subject = subject;
  }

  get value(): T {
    return this._subject.value;
  }

  subscribe(listener: (T) => void): IDisposable {
    return this._subject.subscribe(listener);
  }
}